<template>
	<div class="scroll">
		<button title="Previous Image" :disabled="!hasPreviousImage" @click="$emit('scroll', activeIndex--)">
			<svg-icon icon="caret-up" style="font-size: 2em;" />
		</button>
		<div ref="inputContainer" class="input-container">
			<input
				ref="rangeInput"
				v-model.number="activeIndex"
				class="allow-keybinds"
				type="range"
				min="0"
				:max="series.images.length - 1"
			/>
		</div>
		<button title="Next Image" :disabled="!hasNextImage" @click="$emit('scroll', activeIndex++)">
			<svg-icon icon="caret-down" style="font-size: 2em;" />
		</button>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { eventBus } from '@services/eventBus'
import { import as csToolsImport } from 'cornerstone-tools/dist/cornerstoneTools.js'
import throttle from '@/utils/throttle'
const scrollToIndex = csToolsImport('util/scrollToIndex')

export default {
	name: 'ViewerImageScroll',
	props: {
		series: {
			type: Object,
			required: true,
		},
		image: {
			type: Object,
			required: true,
		},
		stackIndex: {
			type: Number,
			required: true,
		},
	},
	computed: {
		...mapState({
			canvasLayout: state => state.viewer.canvasLayout,
		}),
		activeIndex: {
			get() {
				return this.stackIndex
			},
			set(index) {
				scrollToIndex(this.$parent.$refs.canvas, index)
			},
		},
		hasPreviousImage() {
			return this.stackIndex > 0
		},
		hasNextImage() {
			if (!this.series || !this.series.images) return false
			if (this.frameIndex + 1 >= this.series.images.length) return false
			return this.stackIndex + 1 < this.series.images.length
		},
	},
	watch: {
		canvasLayout: {
			handler() {
				this.setScrollLength()
			},
			deep: true,
		},
	},
	mounted() {
		this.setScrollLength()
		eventBus.on('resize', this.setScrollLength)
		// allow mousewheel scrolling when pointer is over range input
		const onMouseWheel = throttle(e => {
			if (!e || !e.wheelDelta) return
			if (e.wheelDelta < 0 && this.hasNextImage) this.activeIndex++
			else if (e.wheelDelta > 0 && this.hasPreviousImage) this.activeIndex--
		}, 5)
		this.$refs.rangeInput.addEventListener('mousewheel', onMouseWheel)
		this.$once('hook:beforeDestroy', () => {
			this.$refs.rangeInput.removeEventListener('mousewheel', onMouseWheel)
		})
	},
	destroyed() {
		eventBus.off('resize', this.setScrollLength)
	},
	methods: {
		setScrollLength() {
			if (!this.$refs.inputContainer || !this.$refs.rangeInput) return
			const scrollLength = this.$refs.inputContainer.getBoundingClientRect().height
			this.$refs.rangeInput.style.width = scrollLength + 'px'
		},
	},
}
</script>

<style lang="scss" scoped>
.scroll {
	display: flex;
	padding-top: 12px;
	pointer-events: all;
	flex-direction: column;
	width: 40px;
	min-width: 40px;
	height: 100%;
	.input-container {
		flex-grow: 1;
	}
	button {
		width: 40px;
		height: 40px;
		background: transparent;
		border: 0;
		color: #fff;
		opacity: 0.5;
		// prettier-ignore
		text-shadow:
			-1px -1px 0 #000,
			0 -2px 0 #000,
			1px -1px 0 #000,
			-1px 1px 0 #000,
			0 1px 0 #000,
			1px 1px 0 #000;
		&:hover:not(:disabled),
		&:active:not(:disabled) {
			opacity: 1;
			cursor: pointer;
		}
		&:disabled {
			opacity: 0.3;
		}
	}
	input {
		background: transparent;
		appearance: none;
		height: 40px;
		margin-left: 40px;
		transform-origin: top left;
		transform: rotate(90deg);
		cursor: grab;
		&:focus {
			border: 0;
			outline: none;
		}
		&::-webkit-slider-runnable-track {
			width: 0;
		}
		&::-moz-range-track {
			width: 0;
		}
		&::-ms-track,
		&::-ms-fill-lower,
		&::-ms-fill-upper {
			width: 0;
			border: 0;
		}
		$thumbWidth: 18px;
		$thumbHeight: 40px;
		$thumbBorderRadius: 9px;
		$thumbBackground: #fff;
		$thumbBorder: 1px solid #000;
		$thumbActiveOpacity: 1;
		$thumbInactiveOpacity: 0.5;
		&::-webkit-slider-thumb {
			appearance: none;
			border-radius: $thumbBorderRadius;
			height: $thumbWidth;
			width: $thumbHeight;
			background: $thumbBackground;
			opacity: $thumbInactiveOpacity;
			border: $thumbBorder;
			&:hover,
			&:active {
				opacity: $thumbActiveOpacity;
			}
		}
		&::-moz-range-thumb {
			border-radius: $thumbBorderRadius;
			height: $thumbWidth;
			width: $thumbHeight;
			background: $thumbBackground;
			opacity: $thumbInactiveOpacity;
			border: $thumbBorder;
			&:hover,
			&:active {
				opacity: $thumbActiveOpacity;
			}
		}
		&::-ms-thumb {
			border-radius: $thumbBorderRadius;
			height: $thumbWidth;
			width: $thumbHeight;
			background: $thumbBackground;
			opacity: $thumbInactiveOpacity;
			border: $thumbBorder;
			&:hover,
			&:active {
				opacity: $thumbActiveOpacity;
			}
		}
		&::-ms-tooltip {
			display: none;
		}
	}
}
</style>
